var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "e-filters",
        {
          ref: "filters",
          attrs: {
            title: _vm.$t("Filtros"),
            searching: _vm.fetching,
            "search-text": _vm.$t("Pesquisar"),
          },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
        },
        [
          _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("e-store-combo", {
                        attrs: {
                          id: "filters-store_origin",
                          label: _vm.$t("Loja Origem"),
                          required: false,
                        },
                        on: {
                          input: function ($event) {
                            _vm.filters.checkingAccountOriginId = null
                          },
                        },
                        model: {
                          value: _vm.filters.storeOriginId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "storeOriginId", $$v)
                          },
                          expression: "filters.storeOriginId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("e-checking-account-combo", {
                        attrs: {
                          id: "filters-checking_account_origin",
                          label: _vm.$t("Conta corrente Origem"),
                          type: ["All"],
                          required: !!_vm.filters.storeOriginId,
                          "store-id": _vm.filters.storeOriginId,
                          "active-options": "",
                        },
                        model: {
                          value: _vm.filters.checkingAccountOriginId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.filters,
                              "checkingAccountOriginId",
                              $$v
                            )
                          },
                          expression: "filters.checkingAccountOriginId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("e-store-combo", {
                        attrs: {
                          id: "filters-store_destination",
                          label: _vm.$t("Loja Destino"),
                          required: false,
                        },
                        on: {
                          input: function ($event) {
                            _vm.filters.checkingAccountOriginId = null
                          },
                        },
                        model: {
                          value: _vm.filters.storeDestinationId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "storeDestinationId", $$v)
                          },
                          expression: "filters.storeDestinationId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("e-checking-account-combo", {
                        attrs: {
                          id: "filters-checking_account_destination",
                          label: _vm.$t("Conta corrente Destino"),
                          type: ["All"],
                          required: !!_vm.filters.storeDestinationId,
                          "store-id": _vm.filters.storeDestinationId,
                          "active-options": "",
                        },
                        model: {
                          value: _vm.filters.checkingAccountDestinationId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.filters,
                              "checkingAccountDestinationId",
                              $$v
                            )
                          },
                          expression: "filters.checkingAccountDestinationId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "period",
                          type: "date-range-picker",
                          label: _vm.$t("Período"),
                          "time-picker": false,
                          validation: "requiredrange",
                          "validation-messages": {
                            requiredrange: _vm.$t("Informe um período"),
                          },
                        },
                        model: {
                          value: _vm.filters.period,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "period", $$v)
                          },
                          expression: "filters.period",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "transference-imported_file_id",
                          label: _vm.$t("Cód. Id. planilha"),
                          instruction: _vm.$t(
                            "Código de identificação na planilha importada da Prosegur"
                          ),
                          type: "text",
                        },
                        model: {
                          value: _vm.filters.importedFileId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "importedFileId", $$v)
                          },
                          expression: "filters.importedFileId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.filterData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            ref: "checking-account-table",
            staticClass: "bordered",
            attrs: {
              "show-empty": "",
              responsive: "",
              striped: "",
              fields: _vm.fields,
              "tbody-tr-class": _vm.rowClass,
              items: _vm.checkingAccountTransferences,
              "empty-text": _vm.getEmptyTableMessage(
                _vm.$t("registro"),
                "male"
              ),
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(value)",
                fn: function (row) {
                  return [
                    _c(
                      "span",
                      {
                        class:
                          row.item.operationType === "Debit"
                            ? "text-danger"
                            : "text-success",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(
                                row.item.operationType === "Debit"
                                  ? row.item.value * -1
                                  : row.item.value
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "cell(active)",
                fn: function (row) {
                  return [
                    _c("e-status-badge", {
                      attrs: { status: row.item.active },
                    }),
                  ]
                },
              },
              {
                key: "cell(origin)",
                fn: function (row) {
                  return [
                    _c("b", [_vm._v(_vm._s(_vm.$t("Loja")) + ":")]),
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("storeName")(row.item.storeOrigin)) +
                        " "
                    ),
                    _c("br"),
                    _c("b", [_vm._v(_vm._s(_vm.$t("Conta")) + ":")]),
                    _vm._v(
                      " " + _vm._s(row.item.checkingAccountOrigin.name) + " "
                    ),
                  ]
                },
              },
              {
                key: "cell(destination)",
                fn: function (row) {
                  return [
                    _c("b", [_vm._v(_vm._s(_vm.$t("Loja")) + ":")]),
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("storeName")(row.item.storeDestination)) +
                        " "
                    ),
                    _c("br"),
                    _c("b", [_vm._v(_vm._s(_vm.$t("Conta")) + ":")]),
                    _vm._v(
                      " " +
                        _vm._s(row.item.checkingAccountDestination.name) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(infos)",
                fn: function (row) {
                  return [
                    row.item.documentNumber
                      ? _c("div", [
                          _c("b", [_vm._v(_vm._s(_vm.$t("Documento")) + ":")]),
                          _vm._v(
                            " " +
                              _vm._s(
                                row.item.documentType
                                  ? row.item.documentNumber +
                                      "(" +
                                      row.item.documentType +
                                      ")"
                                  : row.item.documentNumber
                              ) +
                              " "
                          ),
                          _c("br"),
                        ])
                      : _vm._e(),
                    _c("b", [_vm._v(_vm._s(_vm.$t("Descrição")) + ":")]),
                    _vm._v(" " + _vm._s(row.item.description) + " "),
                    _c("br"),
                    _c("b", [_vm._v(_vm._s(_vm.$t("Usuário")) + ":")]),
                    _vm._v(" " + _vm._s(row.item.user.name) + " "),
                  ]
                },
              },
              {
                key: "cell(action)",
                fn: function (row) {
                  return [
                    _c("e-grid-actions", {
                      attrs: {
                        "show-update": false,
                        "show-delete": false,
                        buttons: _vm.gridActions(row),
                      },
                      on: {
                        reverse: function ($event) {
                          return _vm.localReverse(row.item)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "4" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "4" } },
                [
                  _c("e-color-subtitled", {
                    attrs: {
                      colors: [
                        {
                          backgroundColor: "table-danger",
                          title: _vm.$t("Estornado"),
                        },
                      ],
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "4" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "checking-account-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("bank-deposit-sidebar", {
        ref: "bankDepositSidebar",
        on: { save: _vm.getData },
      }),
      _c("checking-account-transfer-sidebar", {
        ref: "accountTransferSidebar",
        on: { save: _vm.getData },
      }),
      _vm.fabActions
        ? _c("fab", {
            attrs: {
              "main-tooltip": _vm.$t("Ações"),
              "main-icon": "keyboard_command_key",
              "fixed-tooltip": true,
              actions: _vm.fabActions,
            },
            on: {
              "bulk-deposit": _vm.createBankDepositBulk,
              deposit: _vm.createBankDeposit,
              transference: _vm.createAccountTransfer,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }